import { render, staticRenderFns } from "./HospitalManage.vue?vue&type=template&id=5a35e052&scoped=true&"
import script from "./HospitalManage.vue?vue&type=script&lang=js&"
export * from "./HospitalManage.vue?vue&type=script&lang=js&"
import style0 from "./HospitalManage.vue?vue&type=style&index=0&id=5a35e052&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a35e052",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/changkichoi/pg/oxiconnect-server/tnsc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a35e052')) {
      api.createRecord('5a35e052', component.options)
    } else {
      api.reload('5a35e052', component.options)
    }
    module.hot.accept("./HospitalManage.vue?vue&type=template&id=5a35e052&scoped=true&", function () {
      api.rerender('5a35e052', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aprod/regist/HospitalManage.vue"
export default component.exports